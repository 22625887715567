import * as Yup from 'yup';

export interface TContactFormData {
  is_subscribe: boolean;
  name: string;
  email: string;
  phone_number: string;
  message: string;
}

export const InitialValues: TContactFormData = {
  is_subscribe: true,
  name: '',
  email: '',
  phone_number: '',
  message: '',
};

export const ValidationSchema = Yup.object().shape({
  name: Yup
    .string()
    .required('Please let us know your name'),
  email: Yup
    .string()
    .email('Invalid email format')
    .required('Email is required'),
  phone_number: Yup
    .string()
    // .min(10, 'Phone number required 9 or 10 letters')
    // .max(15, 'Phone number must not exceed 15 digits')
    .required('Phone number is required'),
  message: Yup
    .string()
    .required('This field is required'),
});