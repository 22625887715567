import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Switch from "react-switch";
import { useFormik } from 'formik';
import { PageHeaderText, SectionText } from '../../../utils/TextLabel';
import { Button, ButtonType } from '../../Common/Button';
import { TContactFormData } from '../../../input-form-schema/contact';
import { InitialValues as initialValues, ValidationSchema as validationSchema } from '../../../input-form-schema/contact';
import tw, { styled } from 'twin.macro';
import Modal from '../../Common/Modal';

const InputLabel = styled.label(() => [
  tw`text-spaceGray w-5/6 text-[18px] leading-7 font-light`,
]);

// const InputLabel = styled.label`
//   color: #283941;
//   font-size: 18px;
// `;

const InputMessage = styled.span`
  color: red;
  font-size: 16px;
`;

const Input = styled.input`
  outline: none;
  color: #283941;
  font-size: 18px;
  border-bottom-width: 1px;
  border-color: #E46B25;
  padding: 2px;
`;

const InputSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 0.25rem;
`;


const ContactForm: React.FC = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isShowReceivedForm, setIsShowReceivedForm] = useState(false);
  const [isErrorSubmitForm, setIsErrorSubmitForm] = useState(false);

  const onSubmit = async () => {
    const response = await fetch('https://asia-southeast1-orange-cap-landing-ee0bb.cloudfunctions.net/notifyFormContact', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(values),
    });
    if (response.status === 201) {
      setIsErrorSubmitForm(false);
      Promise
        .all([setIsSubmitted(true), setIsShowReceivedForm(true)])
        .then(() => {
          setTimeout(() => setIsSubmitted(false), 1000);
          setTimeout(() => setIsShowReceivedForm(false), 5000);
        });
    } else {
      setIsErrorSubmitForm(true);
    }
  };

  const formik = useFormik<TContactFormData>({
    initialValues,
    onSubmit,
    validationSchema,
  });

  const {
    values,
    setFieldValue,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    resetForm,
  } = formik;

  useEffect(() => {
    if (isSubmitted) {
      resetForm();
    }
  }, [isSubmitted]);

  return (
    <>
      <div className="bg-[#F3F3F4] w-full flex flex-col items-center py-8 pb-16">
        <div className="flex flex-col gap-y-8 max-w-[550px] p-4 md:p-0">
          <div className="w-full flex flex-col gap-y-3">
            <PageHeaderText>Get in touch</PageHeaderText>
            <span className="text-spaceGray w-5/6 text-[18px] leading-7 font-light">
              Let us know how we can help you. Let’s talk! Our team will get back to you quickly to answer your questions.
            </span>
          </div>
          <form onSubmit={handleSubmit} className="w-full flex flex-col gap-y-3">
            <InputSection>
              <InputLabel>Your name</InputLabel>
              <Input
                name="name"
                className="bg-opacity-0 bg-white"
                value={values.name}
                onChange={(e) => setFieldValue('name', e.target.value)}
                onBlur={handleBlur}
              />
              {errors.name && touched.name && <InputMessage>{errors.name}</InputMessage>}
            </InputSection>
            <InputSection>
              <InputLabel>Email address</InputLabel>
              <Input
                name="email"
                className="bg-opacity-0 bg-white"
                value={values.email}
                inputMode="email"
                onChange={(e) => setFieldValue('email', e.target.value)}
                onBlur={handleBlur}
                required
              />
              {errors.email && touched.email && <InputMessage>{errors.email}</InputMessage>}
            </InputSection>
            <InputSection>
              <InputLabel>Phone</InputLabel>
              <Input
                name="phone_number"
                className="bg-opacity-0 bg-white"
                value={values.phone_number}
                onChange={(e) => setFieldValue('phone_number', e.target.value)}
                onBlur={handleBlur}
                // maxLength={15}
                placeholder="0900000000 or +66900000000"
                // inputMode="numeric"
              />
              {errors.phone_number && touched.phone_number && <InputMessage>{errors.phone_number}</InputMessage>}
            </InputSection>
            <InputSection>
              <InputLabel>Drop us a message!</InputLabel>
              <Input
                name="message"
                className="bg-opacity-0 bg-white"
                value={values.message}
                onChange={(e) => setFieldValue('message', e.target.value)}
                onBlur={handleBlur}
              />
              {errors.message && touched.message && <InputMessage>{errors.message}</InputMessage>}
            </InputSection>
            <div className="flex items-center gap-x-4 mt-2">
              <Switch
                checked={values.is_subscribe}
                onChange={(value) => setFieldValue('is_subscribe', value)}
                onColor="#E46B25"
                checkedIcon={false}
                uncheckedIcon={false}
              />
              <span className="text-base text-[#A7A7A7]">Sign me up for your newsletter</span>
            </div>
            <button
              type="button"
              onClick={() => handleSubmit()}
              className="mt-4 w-[180px] h-12 border-orange border-[2px] text-orange rounded-[5px] font-normal mx-auto transition transform hover:scale-105 text-base"
            >
              Send
            </button>
            {isErrorSubmitForm && (
              <div className="w-full flex flex-col justify-center items-center gap-y-2">
                <span className="text-red-500 text-center font-semibold text-lg">We are so sorry!</span>
                <span className="text-red-400 text-center font-light text-base">There is some network issues, please try again later.</span>
              </div>
            )}
          </form>
        </div>
      </div>
      {isShowReceivedForm && (
        <Modal onCancel={() => setIsShowReceivedForm(false)}>
          <div className="flex flex-col">
            <span className="font-semibold text-orange text-center text-base lg:text-lg">Thank you for your submitting!</span>
            <span className="font-light text-spaceGray text-center text-sm lg:text-base mt-2">
              We have recieved your form, <br />will contact you back as soon as possible!
            </span>
            <Button
              type={ButtonType.SECONDARY}
              onClick={() => setIsShowReceivedForm(false)}
              className="w-[120px] mx-auto mt-4 h-10"
            >
              OK
            </Button>
          </div>
        </Modal>
      )}
    </>
  );
};

export default ContactForm;