/* eslint-disable no-nested-ternary */
import React from 'react';
import tw, { styled } from 'twin.macro';

export enum ButtonType {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  WHITE_GHOST = 'white-ghost',
  BLACK_GHOST = 'black-ghost',
}

interface Section1Props {
  className?: string;
  bgColor?: string;
  fontSize?: number;
  borderColor?: string;
  fontColor?: string;
  onClick?: () => void;
  width?: string | number;
  isIndex?: boolean;
}

interface ButtonProps {
  bgColor?: string;
  borderColor?: string;
  fontColor?: string;
  fontSize?: number;
  width?: number | string;
  isIndex?: boolean;
}

interface TButton {
  className?: string;
  onClick: () => void;
  type: ButtonType;
}

const convertWidth = (width: number | string) => {
  if (typeof width === 'number') {
    return `${width}px`;
  }
  if (typeof width === 'string') {
    return width;
  }
  return 'auto';
};

const hoverMapper: { [color: string]: string; } = {
  white: '#E46B25',
  '#E46B25': 'white',
  transparent: 'white'
};

const _Button = styled.button<ButtonProps>`
  ${tw`h-12 xl:h-14 pl-8 pr-8 xl:pl-12 xl:pr-12 rounded-full text-[16px] xl:text-[20px] color[#E46B25] border-2 border-orange font-semibold z-10 active:opacity-80`};
  ${({ bgColor }) => (bgColor ? `background: ${bgColor}` : 'background: #E46B25')};
  ${({ fontColor }) => fontColor && `color: ${fontColor}`};
  ${({ borderColor }) => borderColor && `border-color:${borderColor}`};
  ${({ width, isIndex }) => (width ? `width: ${convertWidth(width)}` : isIndex ? 'width:auto' : 'width: fit-content')};
  :hover {
    ${({ bgColor }) => bgColor && `background: ${hoverMapper[bgColor]}`};
    ${({ borderColor }) => borderColor && `border-color: ${hoverMapper[borderColor]}`};
    ${({ fontColor }) => (fontColor ? `color: ${hoverMapper[fontColor]}` : `color: white`)};
  }
`;

export const StyledButton: React.FC<Section1Props> = ({
  children,
  className,
  fontSize,
  bgColor,
  borderColor,
  fontColor,
  onClick,
  width,
  isIndex
}) => (
  <>
    <_Button
      className={className}
      fontSize={fontSize}
      bgColor={bgColor}
      borderColor={borderColor}
      fontColor={fontColor}
      onClick={onClick}
      width={width}
      isIndex={isIndex}
    >
      {children}
    </_Button>
  </>
);

const handleSetButtonStyle = (buttonType: ButtonType) => {
  switch (buttonType) {
    case ButtonType.PRIMARY: return 'bg-orange text-white';
    case ButtonType.SECONDARY: return 'border-[2px] border-orange text-orange';
    case ButtonType.WHITE_GHOST: return `border-[2px] border-white text-white`;
    case ButtonType.BLACK_GHOST: return `border-[2px] border-[#47555c] text-[#47555c]`;
    default: return '';
  }
};

export const Button: React.FC<TButton> = ({ onClick, className, type, children }) => {
  return (
    <button
      type="button"
      className={`rounded-[5px] transform transition hover:scale-105 px-3 py-1 w-max h-12 xl:h-[52px] font-normal text-[16px] xl:text-[20px] ${handleSetButtonStyle(type)} ${className}`}
      onClick={onClick}
    >
      {children}
    </button>
  );
};
