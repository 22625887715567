import styled from 'styled-components';

export const SectionText = styled.h2`
  width: max-content;
  font-weight: 600;
  color: #283941;
  line-height: 1.75rem;
  font-size: 1.55rem;
  @media (mid-width: 414px) {
    font-size: 2rem;
  }
`;

export const PageHeaderText = styled.h3`
  width: max-content;
  font-weight: 550;
  color: #283941;
  line-height: 1.75rem;
  font-size: 1.75rem;
  @media (mid-width: 414px) {
    font-size: 2rem;
  }
`;