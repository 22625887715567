/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import tw, { styled } from 'twin.macro';
import { useNavigate } from '@reach/router';
import { useForm } from 'react-hook-form';
import NavigatorIcon from '../../../assets/pages/contact-us/navigator.svg';
import MailIcon from '../../../assets/pages/contact-us/mail.svg';
import PhoneCallIcon from '../../../assets/pages/contact-us/phone-call.svg';
import { Button, ButtonType } from '../../Common/Button';
import { navigate } from 'gatsby-link';
import { PageHeaderText } from '../../../utils/TextLabel';

const HQDirection = 'https://www.google.com/maps/dir//Orange+Cap+HQ+Wannasorn+Tower+9th+Floor,+Room+No.+0904+Phaya+Thai+Rd+Thanon+Phaya+Thai,+Ratchathewi,+Bangkok+10400/@13.7581363,100.5352096,16z/data=!4m8!4m7!1m0!1m5!1m1!1s0x30e29f316702a70f:0x5bb6edd35645b7e7!2m2!1d100.5352096!2d13.7581363';

const StyledContainer = styled.section(() => [
  tw`relative z-10 md:max-w-pageContainerSize w-full px-pageContainerSpace xl:px-0 mx-auto pt-10 sm:mb-20 md:mb-14 xl:mb-0 pb-16`
]);

const Title = styled.h2(() => [
  tw`flex w-full text-[24px] sm:text-[32px] mb-6 text-left font-bold text-spaceGray mt-0 xl:mb-8 sm:mb-6`
]);

const ContactContainer = styled.div(() => [tw`grid max-w-full gap-0 md:gap-9 bg-transparent grid-cols-12 flex flex-col mt-6`]);

{/* <div className="w-[270px] h-[200px] xl:w-[1200px] xl:h-[450px] mx-auto"> */ }
const MapContainer = styled.div`
  margin-right: auto;
  margin-left: auto;
  width: 270px;
  height: 200px;
  @media (min-width: 375px) {
    width: 320px;
    height: 270px;
  }
  @media (min-width: 414px) {
    width: 370px;
  }
  @media (min-width: 768px) {
    width: 700px;
    height: 320px;
  }
  @media (min-width: 1024px) {
    width: 97%;
    height: 400px;
  }
  @media (min-width: 1440px) {
    width: 1200px;
    height: 450px;
  }
`;

const Form = styled.form(() => [
  tw`grid grid-cols-2 gap-6 gap-y-6 md:gap-y-4 col-end-13 col-span-12 xl:col-span-6 lg:col-span-6  md:col-span-12 sm:col-span-12`
]);

const NameContainer = styled.div(() => [tw`relative flex flex-col justify-start col-span-2 sm:col-span-2 pl-1`]);

const EmailContainer = styled.div(() => [tw`relative flex flex-col justify-start col-span-2 sm:col-span-1 pl-1`]);

const TellContainer = styled.div(() => [tw`relative flex flex-col justify-start col-span-2 sm:col-span-1 pl-1`]);

const TextAreaContainer = styled.div(() => [tw`flex flex-col justify-start col-span-2 pl-1`]);

const Input = styled.input(
  () =>
    tw`bg-white w-full h-8 border-[1px] border-[#C4C4C4] pl-8 rounded-[5px] text-[12px] focus:outline-none focus:ring-2 focus:ring-orange focus:border-transparent`
);

const SubTitle = styled.h4(() => [tw`text-black font-semibold text-[12px] sm:text-xl mt-0 mb-2`]);

const Textarea = styled.textarea(
  () =>
    tw`bg-white w-full h-[125px] md:h-[181px] border-[1px] border-[#C4C4C4] pl-6 rounded-[5px] text-[12px] pt-3 focus:outline-none focus:ring-2 focus:ring-orange focus:border-transparent`
);

// const MapContainer = styled.div(() => [
//   tw`flex justify-center items-center h-full col-end-13 col-span-12 xl:col-span-6 lg:col-span-6 sm:col-span-12`
// ]);

const InputIcon = styled.img(() => [tw`absolute top-[30px] left-4 sm:top-[45px]`]);

const Submit = styled.input(() => [
  tw`bg-orange h-10 md:h-14 mb-10 md:mb-0 w-full rounded-full text-ociWhite font-semibold border-2 border-white col-span-2 cursor-pointer hover:bg-white hover:text-orange hover:border-orange active:opacity-80`
]);

const IFrame = styled.iframe(() => [tw``]);

export const Background = styled.div(() => [tw`absolute top-0 h-[785px] w-screen background-color[#E46B25] `]);

interface ContactUsSectionProps {
  className?: string;
  data?: any[];
}

interface TContactGate {
  icon: string;
  text?: string;
  link?: {
    link_to: string;
    link_text: string;
  };
  text_style?: string;
}

const ContactGate: React.FC<TContactGate> = ({ icon, text, link, text_style }) => {
  const navigate = useNavigate();

  return (
    <div className="w-full lg:w-[70%] flex items-start gap-x-2">
      <img
        alt="Contact Gate"
        src={icon}
        className="w-4 h-4 mt-1"
      />
      {text && (
        <span className={`text-spaceGray text-sm md:text-base ${text_style}`}>{text}</span>
      )}
      {link && (
        <span
          className="text-orange text-sm md:text-base selection:text-orange cursor-pointer"
          onClick={() => navigate(link.link_to)}
        >
          {link.link_text}
        </span>
      )}
    </div>
  );
};

const ContactUsSection: React.FC<ContactUsSectionProps> = ({ className }) => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const onSubmit = (data: any) => {
    // TODO Add your action here
  };

  return (
    <>
      <StyledContainer className={className}>
        <div className="flex flex-col">
          <PageHeaderText>Contact us</PageHeaderText>
          <ContactContainer>
            {/* <Form onSubmit={handleSubmit(onSubmit)}>
              <NameContainer>
                <SubTitle>Name</SubTitle>
                <Input type="text" {...register('Name', { required: true, maxLength: 80 })} />
                <InputIcon src={nameIcon} />
              </NameContainer>
              <EmailContainer>
                <SubTitle>Email</SubTitle>
                <Input type="email" {...register('Email', { required: true, maxLength: 80 })} />
                <InputIcon src={emailIcon} />
              </EmailContainer>
              <TellContainer>
                <SubTitle>Tel.</SubTitle>
                <Input type="tel" {...register('Tel', { required: true, maxLength: 80 })} />
                <InputIcon src={telIcon} />
              </TellContainer>
              <TextAreaContainer>
                <SubTitle>Drop us a message!</SubTitle>
                <Textarea placeholder="You message" {...register('others', { required: true, maxLength: 80 })} />
              </TextAreaContainer>
              <Submit type="submit" />
            </Form> */}
            <MapContainer>
              <IFrame
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3875.343462528112!2d100.53299311495903!3d13.75814940085276!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30e29f316702a70f%3A0x5bb6edd35645b7e7!2sOrange%20Cap%20HQ!5e0!3m2!1sen!2sth!4v1637256770024!5m2!1sen!2sth"
                // width="600"
                // height="450"
                style={{ border: '0' }}
                allowFullScreen
                loading="lazy"
                className="w-full h-full"
              />
            </MapContainer>
            <div className="w-full flex flex-col md:grid grid-cols-2">
              <div className="flex flex-col gap-y-4">
                <ContactGate
                  icon={NavigatorIcon}
                  text="Orange Cap Innovative Co. Ltd (Headquarter) Wannasorn Tower 9th Floor, Room No. 0904, Phayathai Rd, Thanon Phaya Thai, Ratchathewi, Bangkok 10400."
                />
                <ContactGate
                  icon={MailIcon}
                  link={{ link_text: 'contact@orangecapinnovative.com', link_to: 'mailto:contact@orangecapinnovative.com' }}
                />
                <ContactGate
                  icon={PhoneCallIcon}
                  text="(+66) 65 554 7498"
                  text_style="font-semibold cursor-default"
                />
              </div>
              <div className="mt-6 md:mt-0">
                <div className="flex flex-col">
                  <a
                    href={HQDirection}
                    target="_blank"
                    className="md:ml-auto"
                  >
                    <button
                      type="button"
                      onClick={() => null}
                      className="px-8 text-white bg-orange py-3 rounded-[5px] text-base"
                    >
                      Get directions
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </ContactContainer>
        </div>
      </StyledContainer>
    </>
  );
};

export default ContactUsSection;
