import * as React from 'react';

import Page from '../components/Common/Page';
import ContactInformation from '../components/pages/ContactPage/ContactInformation';
import ContactForm from '../components/pages/ContactPage/ContactForm';
import IndexLayout from '../layouts';

const ContactPage = () => (
  <IndexLayout transparent>
    <Page>
      <ContactInformation />
      <ContactForm />
    </Page>
  </IndexLayout>
);

export default ContactPage;
