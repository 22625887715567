import React from 'react';
import Close from '../../assets/pages/index/svgs/Close.svg';

interface TModal {
  onCancel: () => void;
  children: React.ReactNode;
}

const Modal: React.FC<TModal> = ({ onCancel, children }) => {
  return (
    <div className="w-full h-full absolute top-[400px] md:top-[450px] xl:top-[300px] z-50 bg-black bg-opacity-40 flex flex-col items-center justify-center">
      <div className="bg-white rounded-[5px] p-4 relative flex flex-col w-[80%] mobile-lg:w-[70%] tablet-vertical:w-[370px] md:w-[400px] mx-auto">
        {/* Close Icon */}
        <div
          className="w-max flex items-center justify-center p-2 rounded-full bg-black bg-opacity-70 absolute -top-3 -right-3"
          onClick={onCancel}
        >
          <img
            alt="Close"
            src={Close}
            className="w-5 h-5 object-cover"
          />
        </div>
        <div className="w-full flex flex-col">
          {children}
        </div>
      </div>
    </div>
  );
};

export default Modal;